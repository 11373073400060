import React from "react";
import "./Hair.scss";

function Hair() {
  return (
    <svg
      width="48"
      height="58"
      viewBox="0 0 48 58"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      className="Hair"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        strokeLinecap="round"
        strokeWidth="4"
        d="M10.1706277,31.9968218 C4.72354258,29.6697587 2,25.7823152 2,20.3344913 C2,14.8866674 3.97240972,9.77517031 7.91722915,5 C9.48224294,11.391675 11.57277,15.2393289 14.1888104,16.5429617 C18.112871,18.4984109 36.9642795,12.1658967 42.9880932,18.4984109 C47.0039689,22.720087 47.0039689,35.2206167 42.9880932,56"
      >
        <animate
          dur="3s"
          repeatCount="indefinite"
          attributeName="d"
          calcMode="spline"
          values="M10.1706277,31.9968218 C4.72354258,29.6697587 2,25.7823152 2,20.3344913 C2,14.8866674 3.97240972,9.77517031 7.91722915,5 C9.48224294,11.391675 11.57277,15.2393289 14.1888104,16.5429617 C18.112871,18.4984109 36.9642795,12.1658967 42.9880932,18.4984109 C47.0039689,22.720087 47.0039689,35.2206167 42.9880932,56;
											 M10.1706277,32.0071077 C4.72354258,29.6800446 2,25.7926011 2,20.3447773 C2,14.8969534 4.72354258,9.78202761 10.1706277,5 C10.2333758,11.3985323 11.57277,15.2496148 14.1888104,16.5532476 C18.112871,18.5086968 36.9642795,12.1761827 42.9880932,18.5086968 C47.0039689,22.7303729 47.0039689,35.2309026 42.9880932,56.010286; 
											 M10.1706277,31.9968218 C4.72354258,29.6697587 2,25.7823152 2,20.3344913 C2,14.8866674 3.36177129,9.77517031 6.08531387,5 C8.87160451,11.391675 11.57277,15.2393289 14.1888104,16.5429617 C18.112871,18.4984109 36.9642795,12.1658967 42.9880932,18.4984109 C47.0039689,22.720087 47.0039689,35.2206167 42.9880932,56; 
											 M10.1706277,31.9968218 C4.72354258,29.6697587 2,25.7823152 2,20.3344913 C2,14.8866674 3.97240972,9.77517031 7.91722915,5 C9.48224294,11.391675 11.57277,15.2393289 14.1888104,16.5429617 C18.112871,18.4984109 36.9642795,12.1658967 42.9880932,18.4984109 C47.0039689,22.720087 47.0039689,35.2206167 42.9880932,56"
          keyTimes="0; 0.25; 0.75; 1"
          keySplines=".5 .5 .5 1;
											.5 0 .5 1;
											.5 0 .5 .5"
        />
      </path>
    </svg>
  );
}

export default Hair;
