import React from "react";
import "./Body.scss";

function Body() {
  return (
    <svg
      className="Body"
      width="189px"
      height="267px"
      viewBox="0 0 189 267"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid meet"
    >
      <g id="Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          className="adrien"
          id="Illustration"
          transform="translate(-29.000000, -22.000000)"
          strokeWidth="4"
        >
          <g className="caracter" id="caracter">
            <polygon
              className="laptop"
              id="Polygon"
              strokeLinecap="round"
              transform="translate(79.307966, 179.160745) rotate(-138.000000) translate(-79.307966, -179.160745) "
              points="79.3079655 131.160745 142.807966 179.160745 79.3079655 227.160745 15.8079655 179.160745"
            />
            <g id="legs" transform="translate(42.000000, 179.000000)">
              <g
                id="Group-Copy"
                transform="translate(89.500000, 52.000000) scale(-1, 1) translate(-89.500000, -52.000000) translate(5.000000, 0.000000)"
              >
                <path
                  d="M6.08246896,41.6325948 C0.478293533,38.8513387 -4.19591856,24.6154925 6.08246896,8.8277452 C12.9347273,-1.69741969 25.7266816,-1.69741969 44.4583319,8.8277452 L138.576675,58.5483873 L131.350731,73.7587424 C51.5746734,54.1954813 9.8185859,43.4867655 6.08246896,41.6325948 Z"
                  id="Path-2"
                >
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    values="0 0 0;1.5 0 0;0 0 0"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M132.591142,71.0520894 L146.281757,77.5261873 C156.00587,94.8229709 162.762225,103.471363 166.550821,103.471363 C172.233714,103.471363 162.745995,73.6857769 160.676837,71.0520894 C159.297398,69.2962977 151.733689,66.509948 137.98571,62.6930403 L132.591142,71.0520894 Z"
                  id="Path-3"
                >
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    values="0 0 0;1 0 0;0 0 0"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
              <g id="Group">
                <path
                  d="M6.08246896,41.6325948 C0.478293533,38.8513387 -4.19591856,24.6154925 6.08246896,8.8277452 C12.9347273,-1.69741969 25.7266816,-1.69741969 44.4583319,8.8277452 L138.576675,58.5483873 L131.350731,73.7587424 C51.5746734,54.1954813 9.8185859,43.4867655 6.08246896,41.6325948 Z"
                  id="Path-2"
                  className="right-leg"
                />
                <path
                  d="M132.591142,71.0520894 L143.378786,75.9652283 C141.256005,96.7589255 142.088912,107.155774 145.877508,107.155774 C151.560401,107.155774 158.959434,73.7587424 157.692733,71.0520894 C156.848266,69.247654 150.279258,66.4613043 137.98571,62.6930403 L132.591142,71.0520894 Z"
                  id="frontLeg"
                />
                <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  values="0 0 0;.5 0 0;0 0 0"
                  dur="3s"
                  repeatCount="indefinite"
                />
              </g>
            </g>
            <g
              id="body"
              transform="translate(75.000000, 73.000000)"
              strokeLinecap="round"
            >
              <path
                d="M0.482714144,65.8432391 C4.46917052,37.1795926 9.70988794,19.3075572 16.2048664,12.2271327 C22.6998448,5.1467083 30.3002216,1.10772262 39.0059966,0.110175684 C41.7488529,13.3826791 47.8373714,20.0189308 57.2715521,20.0189308 C66.7057328,20.0189308 73.45632,13.3826791 77.5233139,0.110175684 C85.9090303,0.567872115 93.2649729,3.46953865 99.5911418,8.81517529 C105.917311,14.1608119 109.462563,46.8389534 110.226898,106.8496"
                id="Path-4"
              />
              <path
                d="M93.0809149,4.66702801 C81.3917418,27.316236 83.5958129,83.8354431 81.2794669,87.0373537 C79.0586322,90.1072379 67.7059788,100.979213 47.2215068,119.653279"
                id="Path-5"
              />
              <path d="M25.5,70.2501639 L25.5,43" id="Path-7" />
            </g>
            <g
              id="head"
              transform="translate(102.000000, 0.000000)"
              strokeLinecap="round"
              className="head"
            >
              <path
                d="M12.4507967,73 L21.9027928,71.1072141 C21.8172867,63.3562233 21.4092707,59.1279514 20.6787449,58.4223983 C19.5829561,57.3640687 16.4470154,60.2589989 11.3614882,58.4223983 C6.27596097,56.5857977 5.80654878,37.4398746 9.0761569,29.09399 C12.345765,20.7481053 30.0394865,24.4033147 32.2565428,27.794968 C34.473599,31.1866213 32.8907362,36.5483763 33.6821676,37.4398746 C34.473599,38.331373 36.340044,34.3851752 39.5711063,36.6631039 C42.8021686,38.9410325 37.5899071,50.0668479 39.5711063,50.0668479 C40.8919058,50.0668479 41.6573804,57.0803033 41.8675303,71.1072141 L49,72.7041417"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Body;
