import React, { useEffect } from "react";
import "./Background.scss";

function Background() {
  useEffect(() => {
    const background = document.getElementById("background");
    const paths = background.children;

    for (var i = 0; i < paths.length; i++) {
      paths[i].addEventListener("mouseenter", function (event) {
        event.target.classList.add("revert");
        setTimeout(function () {
          event.target.classList.remove("revert");
        }, 1000);
      });
    }
  }, []);

  return (
    <svg
      width="504px"
      height="601px"
      viewBox="0 0 504 601"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className="Background"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        // stroke="#000"
        strokeWidth="4"
        strokeLinecap="round"
        fill="none"
        fillRule="evenodd"
        className="animationDelay"
        id="background"
      >
        {/* CODE BLOCK */}
        <path
          d="M270.1515,106.2337 L270.1515,21.9997 C270.1515,10.9997 279.1515,1.9997 290.1515,1.9997 L481.1515,1.9997 C492.1515,1.9997 501.1515,10.9997 501.1515,21.9997 L501.1515,289.3637 C501.1515,300.3637 492.1515,309.3637 481.1515,309.3637 L378.4965,309.3637"
          id="Stroke-1"
        />
        <polyline
          id="Stroke-5"
          points="306.2945 48.2341 293.6985 35.6381 305.9485 23.3881"
        />
        <path d="M316.5208,45.8111 L335.3598,26.9721" id="Stroke-7" />
        <path d="M334.1632,45.7341 L466.3302,45.7341" id="Stroke-13" />
        <path d="M301.1479,74.7341 L359.2029,74.7341" id="Stroke-11" />
        <path d="M300.2899,91.2341 L391.7029,91.2341" id="Stroke-15" />
        <path d="M300.2899,108.2341 L374.7029,108.2341" id="Stroke-17" />
        <path
          d="M324.6458,144.2833 C324.6458,144.2833 316.5208,147.9033 316.5208,156.1533 C316.5208,164.4033 324.6458,169.2783 324.6458,169.2783"
          id="Stroke-19"
        />
        <path d="M329.0208,156.781 L366.3298,156.781" id="Stroke-23" />
        <path
          d="M372.434,169.2787 C372.434,169.2787 380.559,165.6587 380.559,157.4087 C380.559,149.1587 372.434,144.2837 372.434,144.2837"
          id="Stroke-21"
        />
        <path
          d="M413.2466,176.619 C399.4966,176.619 412.6636,156.781 395.6536,156.862 C411.7336,156.862 400.1536,136.943 413.9036,136.943"
          id="Stroke-53"
        />
        <path d="M351.6632,198.7758 L351.6632,262.6928" id="Stroke-25" />
        <path d="M445.9071,212.2341 L363.6501,212.2341" id="Stroke-29" />
        <path d="M406.6289,230.7341 L364.2859,230.7341" id="Stroke-27" />
        <polyline
          id="Stroke-9"
          points="449.3152 256.0347 461.6562 268.3757 449.3202 280.7127"
        />
        {/* FIRST LINE */}
        <path
          className="line__first"
          d="M452.1731,310.3175 L452.1731,482.5675"
          id="Stroke-69"
        />
        <path
          className="firstPoint"
          d="M461.9145,485.2095 C461.9145,490.5895 457.5535,494.9505 452.1735,494.9505 C446.7935,494.9505 442.4315,490.5895 442.4315,485.2095 C442.4315,479.8295 446.7935,475.4685 452.1735,475.4685 C457.5535,475.4685 461.9145,479.8295 461.9145,485.2095"
          id="Fill-68"
          fill="#000"
          stroke="none"
        />
        {/* CUBE */}
        <polygon
          id="Stroke-73"
          strokeLinejoin="round"
          points="415.5568 523.166 452.1728 502.799 488.7898 523.166 452.1728 544.541"
        />
        <polyline
          className="cube"
          id="Stroke-75"
          strokeLinejoin="round"
          points="452.1731 545.230261 452.1731 587.2345 490.7891 564.036327 490.7891 523.1665"
        />
        <polyline
          id="Stroke-77"
          points="452.1731 585.2341 415.5571 562.7601 415.5571 523.1661"
        />
        {/* SECOND LINE */}
        <path
          className="dashLine"
          d="M230.3575,543.046 L405.1555,543.046"
          id="Stroke-71"
          strokeLinejoin="round"
        />
        {/* SCREEN BLOCK */}
        <path
          d="M227.062,487.8217 L227.062,578.6587 C227.062,589.3767 218.294,598.1447 207.576,598.1447 L21.486,598.1447 C10.769,598.1447 2,589.3767 2,578.6587 L2,318.1677 C2,307.4507 10.769,298.6817 21.486,298.6817 L160.591,298.6817"
          id="screen-block"
        />
        <path
          className=""
          d="M21.3653,329.4797 L40.8263,310.0187"
          id="Stroke-33"
        />
        <path
          className=""
          d="M21.3653,310.0184 L40.8263,329.4794"
          id="Stroke-35"
        />
        <path className="" d="M2,339.9261 L174.386,339.9261" id="Stroke-31" />
        <polyline
          id="Stroke-37"
          points="126.6458 359.4534 20.4478 359.4534 20.4478 402.7734 129.5688 402.7734"
        />
        <path d="M34.7373,374.8902 L75.0083,374.8902" id="Stroke-49" />
        <path d="M34.7373,388.5303 L153.6013,388.5303" id="Stroke-51" />
        <polyline
          id="Stroke-39"
          points="20.4476 417.2359 114.5316 417.2359 114.5316 577.8689 20.4476 577.8689"
        />
        <path
          d="M82.153,497.5522 C82.153,509.7492 72.266,519.6362 60.069,519.6362 C47.872,519.6362 37.985,509.7492 37.985,497.5522 C37.985,485.3552 47.872,475.4682 60.069,475.4682 C72.266,475.4682 82.153,485.3552 82.153,497.5522 Z"
          id="Stroke-43"
        />
        <path
          d="M89.4196,497.5522 C89.4196,513.7622 76.2786,526.9032 60.0686,526.9032 C43.8586,526.9032 30.7186,513.7622 30.7186,497.5522 C30.7186,481.3422 43.8586,468.2012 60.0686,468.2012 C76.2786,468.2012 89.4196,481.3422 89.4196,497.5522 Z"
          id="Stroke-45"
        />
        <polyline
          id="Stroke-41"
          points="129.5687 577.8684 129.5687 469.9654 209.6477 469.9654 209.6477 577.8684"
        />
        <polygon
          id="Stroke-47"
          points="160.591 512.541 160.591 536.673 178.546 524.607"
        />
        <path d="M103.5572,285.8637 L103.5572,198.7757" id="Stroke-55" />
        <polygon
          className="oneZeroBlock"
          stroke="none"
          id="Fill-57"
          points="73.946 200.539 133.668 200.539 133.668 54.626 73.946 54.626"
        />
        <g className="rectangleStrips">
          <path d="M93.3905,186.3652 L113.7235,186.3652" id="Stroke-59" />
          <path
            d="M103.5572,156.0626 C108.5742,156.0626 112.6402,160.1296 112.6402,165.1456 C112.6402,170.1626 108.5742,174.2296 103.5572,174.2296 C98.5402,174.2296 94.4742,170.1626 94.4742,165.1456 C94.4742,160.1296 98.5402,156.0626 103.5572,156.0626 Z"
            id="Stroke-60"
          />
          <path d="M93.3905,144.7336 L113.7235,144.7336" id="Stroke-61" />
          <path
            d="M103.5572,114.431 C108.5742,114.431 112.6402,118.498 112.6402,123.514 C112.6402,128.531 108.5742,132.598 103.5572,132.598 C98.5402,132.598 94.4742,128.531 94.4742,123.514 C94.4742,118.498 98.5402,114.431 103.5572,114.431 Z"
            id="Stroke-65"
          />
          <path d="M93.3905,100.8652 L113.7235,100.8652" id="Stroke-62" />
          <path d="M93.3905,86.5318 L113.7235,86.5318" id="Stroke-63" />
          <path d="M93.3905,71.5318 L113.7235,71.5318" id="Stroke-64" />
        </g>
        <polyline
          id="Stroke-66"
          points="103.8072 54.6257 103.8072 32.4647 248.4962 32.4927"
        />
        <path
          className="secondPoint"
          d="M258.2379,32.4927 C258.2379,37.8727 253.8769,42.2337 248.4969,42.2337 C243.1169,42.2337 238.7549,37.8727 238.7549,32.4927 C238.7549,27.1127 243.1169,22.7517 248.4969,22.7517 C253.8769,22.7517 258.2379,27.1127 258.2379,32.4927"
          id="Fill-67"
          fill="#000"
          stroke="none"
        />
      </g>
    </svg>
  );
}

export default Background;
