import React from 'react';

function arrowIcon() {
	return (
		<svg
			width="20px" height="12px" viewBox="0 0 24 12"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			className="copyIcon"
		>
			<path d="M16.55,4 L1.27,4 C0.933106303,4 0.659999994,4.2731063 0.659999994,4.61 L0.659999994,7.45 C0.659999994,7.61087776 0.724562625,7.76502536 0.839256609,7.87783911 C0.953950592,7.99065287 1.10914459,8.0526813 1.27,8.05 L16.55,8.05 L16.55,10.39 C16.5521449,10.8817881 16.8493604,11.3242026 17.3038384,11.5121118 C17.7583164,11.700021 18.2811816,11.5966782 18.63,11.25 L23,6.86 C23.2296234,6.63273277 23.3588243,6.32307473 23.3588243,6 C23.3588243,5.67692527 23.2296234,5.36726723 23,5.14 L18.65,0.78 C18.3011816,0.433321756 17.7783164,0.329979048 17.3238384,0.517888215 C16.8693604,0.705797382 16.5721449,1.14821194 16.57,1.64 L16.57,4 L16.55,4 Z" ></path>
		</svg>
	);
}

export default arrowIcon;
